import { Component, Vue, Inject } from "vue-property-decorator";
import { STYLE_CLASSES } from "@/shared/constants/StyleConstants";
import {
  FORM_CONSTANTS,
  FORM_MESSAGES,
  FORM_VALIDATORS
} from "@/shared/constants/FormConstants";
import { User } from "../models/User";
import { Http } from "@status/codes";
import { REGISTER_CONSTANS, WOLKVOX_INDEX } from "../constants/LoginConstans";
import { AuthService } from "../services/AuthService";
import SnackbarTemplate from "@/shared/components/snackbar/SnackbarTemplate";
import firebase from "firebase/app";
import "firebase/auth";

@Component({
  name: "Register"
})
export default class Register extends Vue {
  @Inject() authService!: AuthService;

  cardClass = STYLE_CLASSES.CARD;
  textFieldClass = STYLE_CLASSES.TEXT_FIELD;
  secBtnClass = STYLE_CLASSES.SECONDARY_BTN;
  priBtnClass = STYLE_CLASSES.PRIMARY_BTN;

  PAGE_TITLE = REGISTER_CONSTANS.PAGE_TITLE;
  ID_DOC = REGISTER_CONSTANS.LABEL.USER;
  PASSWORD = REGISTER_CONSTANS.LABEL.PASSWORD;
  CONFIRM_PASS = REGISTER_CONSTANS.LABEL.CONFIRM_PASS;
  REGISTER_BTN = REGISTER_CONSTANS.BUTTON.REGISTER;
  CANCEL_BTN = FORM_CONSTANTS.BUTTON.CANCEL;
  SITE_KEY = process.env.VUE_APP_SITE_KEY;

  alphanumericPatter = [FORM_VALIDATORS.ALPHANUMERIC_PATTERN];
  emailField = [FORM_VALIDATORS.EMAIL_FIELD];
  requiredField = [FORM_VALIDATORS.REQUIRED_FIELD];
  passwordRules = [
    (v: string) => v.length >= 4 || REGISTER_CONSTANS.MESSAGE.MIN_LENGTH
  ];

  doc = "";
  user = "";
  password = "";
  repeatPass = "";
  registerForm = false;
  disabled = false;
  captchaValue = "";
  applicationVerifier!: firebase.auth.RecaptchaVerifier;

  async mounted() {
    if (this.$route.params.info) {
      const resp = await this.authService.decodeToken(this.$route.params.info);

      if (resp.code === Http.Ok && resp.data) {
        this.doc = resp.data.id;
        this.disabled = true;
      }
    } else {
      this.applicationVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "normal",
          callback: (response: string) => {
            this.captchaValue = response;
          }
        }
      );

      this.applicationVerifier.render();
    }
  }

  async register() {
    if (this.registerForm) {
      if (!this.disabled) {
        this.sendSMS();
      } else {
        try {
          const userToSave: User = {
            password: this.password,
            basicInfo: {
              idNumber: this.doc
            }
          };

          const response = await this.authService.register(userToSave);

          if (response.code === Http.Ok) {
            SnackbarTemplate.popSuccess(response.message);
            this.$router.push("/login");
          } else {
            SnackbarTemplate.popInfo(response.message);
          }
        } catch (error) {
          SnackbarTemplate.popError(FORM_MESSAGES.REQUEST_FAILED);
        }
      }
    } else {
      SnackbarTemplate.popWarning(FORM_MESSAGES.FORM_ERRORS);
    }
  }

  async sendSMS() {
    if (this.captchaValue && this.doc) {
      try {
        const validUser = await this.authService.validateUser(this.doc);

        if (validUser.code === Http.NotFound) {
          const id = this.doc.replace(/[a-zA-Z]/g, "");

          const resp = await this.authService.sendSMS(this.doc, +id);

          if (resp.status === Http.Ok && resp.data.telefono_celular) {
            let phoneNumber: string = resp.data.telefono_celular;
            phoneNumber = phoneNumber.substring(phoneNumber.length - 4);

            SnackbarTemplate.popSuccess(
              REGISTER_CONSTANS.MESSAGE.SMS_SENT + phoneNumber
            );
            this.$router.push("/login");
          } else {
            SnackbarTemplate.popWarning(resp.message);
          }
        } else {
          SnackbarTemplate.popWarning(validUser.message);
        }
      } catch (error) {
        console.log("error", error);
        if ((error as any).toString().indexOf(WOLKVOX_INDEX) > 0) {
          SnackbarTemplate.popWarning(REGISTER_CONSTANS.MESSAGE.NOT_VALID);
          return;
        }

        SnackbarTemplate.popError(FORM_MESSAGES.REQUEST_FAILED);
      }
    } else {
      SnackbarTemplate.popWarning(FORM_MESSAGES.FORM_ERRORS);
    }
  }

  matchPassword() {
    return (
      this.repeatPass === this.password || REGISTER_CONSTANS.MESSAGE.MATCH_PASS
    );
  }
}

export const NOTIFICATION_MESSAGES = {
  ERROR_MESSAGE:
    "Ha ocurrido un error, es posible que no recibas notificaciones en tu dispositivo",
  REJECT_MESSAGE: "No recibirás notificaciones en tu dispositivo",
  ERROR_DELETING: "Ocurrió un error eliminando la notificación",
  ERROR_GETTING: "Ocurrió un error obteniendo las notificaciones",
  ERROR: "Ocurrió un error desvinculando la suscripción. Intente más tarde",
  ERROR_SUBSCRIBE:
    "Ocurrió un error, verifique que la recepción de notificaciones se encuentra habilitado en tú navegador",
  ERROR_TRY_LATER: "Ha ocurrrido un error, por favor intente más tarde"
};
export const NOTIFICATION_PERMISSIONS = {
  GRANTED: "granted",
  DENIED: "denied",
  DEFAULT: "default"
};

export const NOTIFICATION_EVENTS = {
  GET_NOTIFICATIONS: "eventGetNotifications"
};

import ModalTemplate from "@/shared/components/modalTemplate/ModalTemplate.vue";
import SnackbarTemplate from "@/shared/components/snackbar/SnackbarTemplate";
import { FORM_VALIDATORS } from "@/shared/constants/FormConstants";
import { STYLE_CLASSES } from "@/shared/constants/StyleConstants";
import { Http } from "@status/codes";
import { Component, Vue, Inject } from "vue-property-decorator";
import { REGISTER_CONSTANS } from "../Login/constants/LoginConstans";
import {User} from '../Login/models/User'
import { AuthService } from "../Login/services/AuthService";
import { NOTIFICATION_MESSAGES } from "../Notifications/constants/NotificationConstants";
import { MODAL_CONSTANTS } from "./constants/CreateUserConstants";
@Component({
    name: "CreateUser",
    components: {
      ModalTemplate,
    }
})
export default class CreateUser extends Vue {
  @Inject() authService!: AuthService;

  ID_DOC = REGISTER_CONSTANS.LABEL.USER;
  TEL_NUMBER = REGISTER_CONSTANS.LABEL.TEL_NUMBER;
  EMAIL_LABEL = REGISTER_CONSTANS.LABEL.EMAIL;
  MODAL_TITLE = MODAL_CONSTANTS.TITLE;
  MODAL_BODY = MODAL_CONSTANTS.BODY;

  cardClass = STYLE_CLASSES.CARD;
  textFieldClass = STYLE_CLASSES.TEXT_FIELD;
  secBtnClass = STYLE_CLASSES.SECONDARY_BTN;
  priBtnClass = STYLE_CLASSES.PRIMARY_BTN;

  requiredField = [FORM_VALIDATORS.REQUIRED_FIELD];
  alphanumericPatter = [FORM_VALIDATORS.ALPHANUMERIC_PATTERN];

  doc = "";
  telNumber = "";
  userEmail = "";
  form = false;
  dialog = false;

  async saveUser(){
    try {
      const userToSave: User = {
        basicInfo: {
          idNumber: this.doc,
        },
        detailInfo: {
          mobilePhoneMain: this.telNumber
        },
        user: this.doc,
        password: this.doc,
      };

      const {code,message} = await this.authService.register(userToSave);

      switch (code) {
        case Http.Ok:
          if(message){
            SnackbarTemplate.popSuccess(message);
          }
          break;
        case Http.NotFound:
          if(message){
            SnackbarTemplate.popWarning(message);
          }
          break;
        case Http.Found:
          if(message){
            this.dialog = true;
          }
          break;
        case Http.BadRequest:
          if(message){
            SnackbarTemplate.popError(message);
          }
          break;
      }
    } catch (error) {
      SnackbarTemplate.popError(NOTIFICATION_MESSAGES.ERROR_TRY_LATER);
    }
  }

  async resetUserPassword(){
    try {

      const {code, message} = await this.authService.resetPassword(this.doc, this.doc);
      if(code === Http.Ok) SnackbarTemplate.popSuccess(message);

    } catch (error) {
      SnackbarTemplate.popError(NOTIFICATION_MESSAGES.ERROR_TRY_LATER);
    }
  }

  async handleModalAccept(){
    await this.resetUserPassword();
    this.dialog = false;
  }

  handleModalCancel(){
    this.dialog = false;
  }

}
export const LOGIN_CONSTANTS = {
  PAGE_TITLE: "Bienvenido",
  BUTTON: {
    LOGIN: "Iniciar sesión",
    LOGIN_INV: "Invitado",
    REGISTER: "Registrarme",
    ENTER: "Ingresar"
  },
  LABEL: {
    USER: "Documento de identidad",
    PASSWORD: "Contraseña",
    FORGOT: "¿Has olvidado tu contraseña?"
  },
  MESSAGE: {
    INVALID_USER: "Usuario o contraseña incorrectos",
    INVALID_FORM: "Ingrese el documento de identidad y la contraseña"
  },
  PATIENT_USER: "patient",
  GUEST_USER: "guest"
};

export const REGISTER_CONSTANS = {
  PAGE_TITLE: "Registro para pacientes",
  APPOINTMENT_TITLE: "Consulta de citas",
  BUTTON: {
    REGISTER: "Registrarme",
    CONTINUE: "Continuar"
  },
  LABEL: {
    USER: "Documento de identidad*",
    PASSWORD: "Contraseña*",
    CONFIRM_PASS: "Confirmar contraseña*",
    TEL_NUMBER: "Teléfono/Celular*",
    EMAIL: "Correo Electrónico",
  },
  MESSAGE: {
    MIN_LENGTH: "La contraseña debe contener al menos 4 caracteres",
    MATCH_PASS: "Las constraseñas no coinciden",
    SMS_SENT: "Se ha enviado un mensaje al número de celular ****",
    NOT_VALID: "Datos de entrada no válidos"
  }
};

export const RESET_PASS_CONSTANTS = {
  PAGE_TITLE: "Recuperar contraseña",
  CONFIRM_CODE: "Por favor ingrese el código de verificación",
  CODE_SENT: "El código ha sido enviado al número ******",
  BUTTON: {
    SEND: "Enviar",
    RESET_PASS: "Restablecer",
    CONFIRM: "Confirmar"
  },
  LABEL: {
    ID_NUMBER: "Número de documento*",
    NEW_PASSWORD: "Nueva contraseña*",
    CONFIRM_PASS: "Confirmar contraseña*",
    CONFIRM: "Código"
  }
};

export const WOLKVOX_INDEX = "status code 400";

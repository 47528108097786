import ChatEmbedded from "@/components/ChatEmbedded/ChatEmbedded.vue";
import CreateUser from "@/components/CreateUser/CreateUser.vue";
import Login from "@/components/Login/Login.vue";
import Register from "@/components/Login/Register/Register.vue";
import ResetPass from "@/components/Login/ResetPass/ResetPass.vue";
import store from "@/store";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "/patient",
        name: "Chat",
        component: ChatEmbedded
      }
    ]
  },
  {
    path: "/chat/guest",
    name: "Chat",
    component: ChatEmbedded
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/user/:type/:info?",
    name: "Register",
    component: Register
  },
  {
    path: "/recover",
    name: "ResetPass",
    component: ResetPass
  },
  {
    path: "/create-user",
    name: "CreateUser",
    component: CreateUser
  },
  {
    path: "**",
    redirect: "/patient"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem("TOKEN") == null) {
      store.commit("changeSession", false);
      next({
        path: "/chat/guest",
        params: { nextUrl: to.fullPath }
      });
    } else {
      store.commit("changeSession", true);
      next();
    }
  } else {
    next();
  }
});

export default router;

import { Component, Prop, Vue } from "vue-property-decorator";
import Vuetify from "vuetify";
import { STYLE_CLASSES } from "@/shared/constants/StyleConstants";
import { FORM_CONSTANTS } from "@/shared/constants/FormConstants";

Vue.use(Vuetify);
@Component({
  name: "ModalTemplate"
})
export default class ModalTemplate extends Vue {
  @Prop({ default: false }) private dialog!: boolean;
  @Prop() private isWarningModal!: boolean;
  @Prop() private modalText!: string;
  @Prop() private modalTitle!: string;
  @Prop({ default: "" }) private modalTextCancel!: string;
  @Prop({ default: "" }) private modalTextAccept!: string;

  primaryBtnClass = STYLE_CLASSES.PRIMARY_BTN;
  secBtnClass = STYLE_CLASSES.SECONDARY_BTN;
  terBtnClass = STYLE_CLASSES.TERTIARY_BTN;
  cardClass = STYLE_CLASSES.CARD;

  get CANCEL_BTN() {
    return this.modalTextCancel
      ? this.modalTextCancel
      : FORM_CONSTANTS.BUTTON.CANCEL;
  }

  get ACCEPT_BTN() {
    return this.modalTextAccept
      ? this.modalTextAccept
      : FORM_CONSTANTS.BUTTON.ACCEPT;
  }

  private accept() {
    this.$emit("modalAccept");
  }

  private cancel() {
    this.$emit("modalCancel");
  }
}

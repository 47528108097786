import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import store from "@/store";
import { LOGIN_CONSTANTS } from "../Login/constants/LoginConstans";
import {
  FORM_CONSTANTS,
  FORM_MESSAGES
} from "@/shared/constants/FormConstants";
import { STYLE_CLASSES } from "@/shared/constants/StyleConstants";
import {
  NOTIFICATION_MESSAGES,
  NOTIFICATION_PERMISSIONS
} from "../Notifications/constants/NotificationConstants";
import { NotificationMessage } from "../Notifications/models/NotificationMessage";
import { NotificationService } from "../Notifications/services/NotificationService";
import { Http } from "@status/codes";
import SnackbarTemplate from "@/shared/components/snackbar/SnackbarTemplate";
import { Subscription } from "../Notifications/models/Subscription";
import { Utils } from "@/shared/utils/utils";

@Component
export default class ChatEmbedded extends Vue {
  @Inject() notificationService!: NotificationService;
  db = firebase.firestore();

  icon = "mdi-account";
  dialog = false;
  type!: string | null;
  url =
    "https://chat.neumomed.com/landing/ug3TM8Ze2PegTC75G_c/playbook/602d63dc9fb5be534f79a02f?";
  source = "";
  hiddenBadge = false;
  notifications: Array<NotificationMessage> = [];

  CANCEL_BTN = FORM_CONSTANTS.BUTTON.CANCEL;
  ACCEPT_BTN = FORM_CONSTANTS.BUTTON.ACCEPT;
  MODAL_TITLE = FORM_CONSTANTS.MODAL.TITLE;
  MODAL_TEXT = FORM_CONSTANTS.MODAL.TEXT;
  cardClass = STYLE_CLASSES.CARD;
  secBtnClass = STYLE_CLASSES.SECONDARY_BTN;
  priBtnClass = STYLE_CLASSES.PRIMARY_BTN;
  fab = false;
  transition = "slide-y-reverse-transition";
  realTimeDb: any;

  mounted() {
    this.type = localStorage.getItem("TYPE");

    if (this.type === LOGIN_CONSTANTS.PATIENT_USER) {
      this.icon = "mdi-exit-to-app";

      this.createSubscription();
      this.getNotifications();

      this.checkToken();
    } else if (this.type === null) {
      localStorage.removeItem("TOKEN");
      localStorage.setItem("TYPE", LOGIN_CONSTANTS.GUEST_USER);
    }

    const url = `info=${localStorage.getItem(
      "RAND"
    )}&type=${localStorage.getItem("TYPE")}`;
    this.source = this.url + url;
  }

  checkToken() {
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const token = await user.getIdToken();
        if (token !== localStorage.getItem("TOKEN")) {
          localStorage.setItem("TOKEN", token);
        }
      }
    });
  }

  logout() {
    if (this.type === LOGIN_CONSTANTS.PATIENT_USER) {
      this.dialog = true;
    } else {
      this.signOut();
    }
  }

  async signOut() {
    if (this.type === LOGIN_CONSTANTS.PATIENT_USER) {
      firebase.auth().signOut();
      store.commit("changeSession", false);
      await this.deleteSubscription();
      this.realTimeDb();
    }

    localStorage.removeItem("TOKEN");
    localStorage.removeItem("TYPE");
    localStorage.removeItem("RAND");
    this.$router.push("/login");
  }

  @Watch("notifications")
  updateCounter() {
    this.hiddenBadge = this.notifications.length > 0 ? true : false;
  }

  async createSubscription(): Promise<void> {
    if (
      'Notification' in window && (Notification.permission === NOTIFICATION_PERMISSIONS.DENIED ||
        !(FORM_CONSTANTS.GLOBAL_VARS.SERVICE_WORKER in navigator) ||
        !(FORM_CONSTANTS.GLOBAL_VARS.PUSH_MANAGER in window))
    ) {
      return;
    }

    Notification.requestPermission()
      .then(async respo => {
        if (respo == NOTIFICATION_PERMISSIONS.GRANTED) {
          const uid = localStorage.getItem("UID");

          if (uid) {
            const registration = await navigator.serviceWorker.getRegistration();
            registration?.pushManager
              .subscribe({
                userVisibleOnly: true,
                applicationServerKey: Utils.urlBase64ToUint8Array(
                  process.env.VUE_APP_PUBLIC_VAPID_KEY
                )
              })
              .then(async subscription => {
                const data: Subscription = {
                  subscription: JSON.parse(JSON.stringify(subscription)),
                  userKey: uid
                };
                const response = await this.notificationService.saveSubscription(
                  data
                );

                if (response.code !== Http.Ok) {
                  SnackbarTemplate.popError(
                    NOTIFICATION_MESSAGES.ERROR_MESSAGE
                  );
                }
              })
              .catch(() => {
                SnackbarTemplate.popError(
                  NOTIFICATION_MESSAGES.ERROR_SUBSCRIBE
                );
              });
          } else {
            SnackbarTemplate.popError(NOTIFICATION_MESSAGES.ERROR_MESSAGE);
          }
        } else {
          SnackbarTemplate.popError(NOTIFICATION_MESSAGES.REJECT_MESSAGE);
        }
      })
      .catch(e => {
        SnackbarTemplate.popError(NOTIFICATION_MESSAGES.ERROR_MESSAGE);
      });
  }

  async getNotifications() {
    const uid = localStorage.getItem("UID");
    if (uid) {
      try {
        this.realTimeDb = this.db
          .collection("notifications")
          .where("user", "==", this.db.collection("users").doc(uid))
          .where("channels", "array-contains", "push")
          .orderBy("dateCreated", "desc")
          .onSnapshot(snap => {
            if (!snap.empty) {
              this.notifications = [];
              this.hiddenBadge = true;

              snap.forEach(notification => {
                this.notifications.push({
                  _key: notification.id,
                  message: notification.data().message,
                  userId: uid
                });
              });
            } else {
              this.notifications = [];
              this.hiddenBadge = false;
            }
          });
      } catch (error) {
        this.hiddenBadge = false;
        SnackbarTemplate.popError(NOTIFICATION_MESSAGES.ERROR_GETTING);
      }
    }
  }

  async deleteSubscription() {
    if (
      'Notification' in window && Notification.permission === NOTIFICATION_PERMISSIONS.GRANTED &&
      FORM_CONSTANTS.GLOBAL_VARS.SERVICE_WORKER in navigator &&
      FORM_CONSTANTS.GLOBAL_VARS.PUSH_MANAGER in window
    ) {
      try {
        const registration = await navigator.serviceWorker.getRegistration();
        const subscription = await registration?.pushManager.getSubscription();

        if (subscription) {
          const uid = localStorage.getItem("UID");

          if (uid) {
            const data: Subscription = {
              subscription: JSON.parse(JSON.stringify(subscription)),
              userKey: uid
            };
            await this.notificationService.deleteSubscription(data);
          }
        }
      } catch {
        SnackbarTemplate.popError(NOTIFICATION_MESSAGES.ERROR);
      }
    }
  }

  async deleteNotification(id: string) {
    try {
      const response = await this.notificationService.deleteNotification(id);

      if (response.code !== Http.Ok) {
        SnackbarTemplate.popError(NOTIFICATION_MESSAGES.ERROR_DELETING);
      }
    } catch (error) {
      SnackbarTemplate.popError(FORM_MESSAGES.REQUEST_FAILED);
    }
  }
}
